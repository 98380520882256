



import Vue from "vue";
import { mapState } from "vuex";
import { auth } from "@/utils";
import { dispatch } from "@/store";

export default Vue.extend({
  computed: {
    ...mapState(["user"])
  },
  mounted() {
    if (this.user.isSignIn) {
      location.href = `https://finance-test.angrymiao.com/v1/token/${auth.getToken()}`;
    } else {
      dispatch.userSignIn();
    }
  }
});
